import React from "react";
import { ReactComponent as WorkIcon } from "../assets/work.svg"
import { ReactComponent as SchoolIcon } from "../assets/school.svg";

import timelineElements from "./timelineEvents";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

const WorkExperience = () => {
    const workIconStyles = { background: "#3B82F6" }; // Blue color
    const schoolIconStyles = { background: "#F87171" }; // Red color
  
    return (
      <div className="text-white"> {/* Add text-white class to make the text visible */}
        <h1 className="text-4xl font-bold inline border-b-4 border-gray-500 mb-4">
          Work Experience
        </h1>
        
        <VerticalTimeline>
          {timelineElements.map((element) => {
            const isWorkIcon = element.icon === "work";
            const showButton = element.buttonText !== undefined && element.buttonText !== null && element.buttonText !== "";
  
            return (
              <VerticalTimelineElement
                key={element.id}
                date={element.date}
                dateClassName="text-white"
                iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
                className="text-black"
              >
                <h3 className="vertical-timeline-element-title">
                  {element.title}
                </h3>
                <h5 className="vertical-timeline-element-subtitle">
                  {element.location}
                </h5>
                <p id="description">{element.description}</p>
                {showButton && (
                  <a
                    className={`button ${
                      isWorkIcon ? "workButton" : "schoolButton"
                    }`}
                    href="/"
                  >
                    {element.buttonText}
                  </a>
                )}
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
        
      </div>
    );
  };
  
  export default WorkExperience;