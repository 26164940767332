import React, { useState } from "react";
import WorkExperience from "./WorkExperience";

const About = () => {
  const [showWorkExperience, setShowWorkExperience] = useState(false);

  const toggleWorkExperience = () => {
    setShowWorkExperience(!showWorkExperience);
  };

  return (
    <div
      id="about"
      className="w-full  bg-gradient-to-b from-gray-700 to-black text-white pb-40" >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-4">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>
        <p className="text-xl"> 
        Experienced Software Developer with 5+ years of hands-on expertise in designing and implementing robust business solutions. Specialized in Java, JavaScript, Python, and SQL, proficient in web technologies such as Spring, Spring Boot, REST, React.js, and Node.js. Demonstrated success in database management (MySQL, PostgreSQL, Oracle, MongoDB) and cloud platforms/tools (AWS, GCP, Jenkins, Docker, Kubernetes(EKS, GKE)). Accomplished projects at CGI and Capgemini showcase my ability to drive operational improvements. Committed to continuous learning and innovation in software development.
        </p>
       {/* Link to trigger the display of work experience */}
       <button onClick={toggleWorkExperience} className="text-blue-500 underline cursor-pointer mt-4">
          {showWorkExperience ? 'Hide Work Experience' : 'View Work Experience'}
        </button>

        {/* Show the WorkExperience component when the state is true */}
        {showWorkExperience && <WorkExperience />}
      </div>
    </div>
  );
};

export default About;
