let timelineElements = [
    {
        id: 1,
        title: "Graduate Student at Northeastern University",
        location: "Boston",
        description:
            "Pursuing Masters in Information Systems specaiizing in Software Engineering",
        //buttonText: "View Frontend Projects",
        date: "September 2022 - May 2024",
        icon: "school",
    },
    {
        id: 2,
        title: "CGI | Senior Software Engineer",
        location: "Bangalore,India",
        description:
            "Revitalized the Java back-end for optimal cloud efficiency and demonstrated innovative leadership in enhancing React performance. Pioneered a transformative approach to code architecture, resulting in substantial cost savings and a remarkable boost in application responsiveness.",
        //buttonText: "View Backend Projects",
        date: "March 2022 - August 2022",
        icon: "work",
    },
    {
        id: 3,
        title: "Capgemini | Consultant(Full stack Software Developer)",
        location: "Banaglore, India",
        description:
            "Elevated organizational data processing capabilities through a revolutionary approach, leading the architectural design of scalable microservices. Spearheaded the implementation of an efficient CI/CD pipeline, significantly reducing deployment times to mere minutes. Additionally, played a pivotal role in boosting sales by empowering teams with dynamic React solutions",
        //buttonText: "Company Website",
        date: "January 2017 - August 2020",
        icon: "work",
    },
    {
        id: 4,
        title: "Accenture | Application Development Anlayst",
        location: "Mumbai,India",
        description:
            "Application Developer proficient in backend api testing with Junit. Demonstrated prowess in transforming legacy applications to ORM frameworks, notably enhancing response times",
        //buttonText: "Course Certificate",
        date: "October 2014 - September 2016",
        icon: "work",
    },


];

export default timelineElements;
